

import { Auth0Provider } from "@auth0/auth0-react"
import React from "react"
import { useNavigate } from "react-router-dom"

export const Auth0ProviderWithHistory = ({ children }) => {
  const navigate = useNavigate()

  //const domain =  //process.env.REACT_APP_AUTH0_DOMAIN
  //const clientId = //process.env.REACT_APP_AUTH0_CLIENT_ID
  //const audience = //process.env.REACT_APP_AUTH0_AUDIENCE

  const domain = "deeptradeone.eu.auth0.com"
  const clientId = "IB8bsPFTBO0wEnlICFH3y9OfgZTAWBt8"
  const audience = "https://api.deeptrade.one"
  
  const onRedirectCallback = (appState) => {
    navigate(appState?.returnTo || window.location.pathname)
  }

  if (!(domain && clientId && audience)) {
    return null
  }

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      audience={audience}
      redirectUri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
      //responseType="id_token" // ?
      //scope="all.workspaces.read all.workspaces.write email read:current_user" // ?
      //scope="all.workspaces.read all.workspaces.write"
    >
      {children}
    </Auth0Provider>
  )
}